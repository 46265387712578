/* Root Variables */
:root {
    /* #9d2462 */
    --primary-color: 157, 36, 98;

    /* #efe4c3 */
    --title-color: 239, 228, 195;

    /* #0e1415 */
    --card-color: 14, 20, 21;

    /* #c9ab81 */
    --btn-color: 201, 171, 129;

    /* #e99c60 */
    --action-color: 233, 156, 96;

    --transition-duration: 350ms;
}

*,
*::before,
*::after {
    padding: 0;
    margin: 0;
    font-family: 'Roboto', sans-serif !important;
    box-sizing: border-box;
}

body {
    background-color: #090d0e;
    color: rgba(255, 255, 255, 1);
    overflow-x: hidden !important;
}

ul,
ol {
    list-style: none;
    padding: 0;
    margin: 0;
}

.section-subtitle {
    font-family: 'Miniver', cursive !important;
    color: rgba(var(--action-color), 1);
    text-transform: capitalize;
    font-size: 2rem;
}

section {
    overflow: hidden;
}

.page {
    min-height: 100vh;
    padding: 9rem 0 5rem !important;
}

.page .page-title {
    text-transform: capitalize;
    font-size: 2.5rem;
    color: rgba(var(--title-color), 1);
    padding-bottom: 2rem;
}

/* Text Limit */
.text-limit {
    overflow: hidden !important;
    text-overflow: ellipsis !important;
    display: -webkit-box !important;
    -webkit-line-clamp: var(--lines) !important;
    line-clamp: var(--lines) !important;
    -webkit-box-orient: vertical !important;
}

/* ********************************************************************************* */
/* ********************************   RTL   **************************************** */
/* ********************************************************************************* */
[dir='rtl'] *,
[dir='rtl'] *::before,
[dir='rtl'] *::after {
    font-family: 'Cairo', sans-serif !important;
}

[dir='rtl'] .section-subtitle {
    font-family: 'Gulzar', serif !important;
}
