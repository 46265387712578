/* Section Image */
#about-us-page .image {
    background: var(--image-url);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

/* Text Container */
#about-us-page .text-container {
    color: rgba(var(--title-color), 1);
    padding-left: 3rem;
}

@media only screen and (max-width: 992px) {
    #about-us-page .text-container {
        padding-left: 0.5rem;
    }
}

/* Section Title */
#about-us-page .text-container .title {
    font-size: 4rem;
    line-height: 1;
    margin: 1rem 0;
}

@media only screen and (max-width: 992px) {
    #about-us-page .text-container .title {
        font-size: 2.5rem;
    }
}

/* Section Description */
#about-us-page .text-container .description {
    color: rgba(255, 255, 255, 0.5);
    margin: 1rem 0 2rem;
    text-align: justify;
}

/* RTL */
[dir='rtl'] #about-us-page .text-container {
    padding-right: 3rem;
    padding-left: 0.5rem;
}

@media only screen and (max-width: 992px) {
    [dir='rtl'] #about-us-page .text-container {
        padding-right: 0.5rem;
    }
}

[dir='rtl'] #about-us-page .text-container .title {
    line-height: 1.5;
}
