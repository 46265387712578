/* Navbar Image */
#navbar-component img {
	max-width: 9rem;
}

/* Translation */
#navbar-component #translation {
	transition: color var(--transition-duration);
}

/* NavLink */
#navbar-component .nav-link,
#navbar-component #translation {
	font-size: 1.1rem;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	#navbar-component .nav-link,
	#navbar-component #translation {
		font-size: 0.8rem;
	}
}

#navbar-component .nav-link.active,
#navbar-component .nav-link:hover,
#navbar-component #translation:hover {
	color: rgba(var(--action-color), 1);
}

/* Navbar Toggler */
#navbar-component .navbar-toggler {
	border: none !important;
}

#navbar-component .navbar-toggler:focus {
	box-shadow: none !important;
}

#navbar-component .navbar-toggler-icon {
	background-image: none !important;
	background-color: rgba(var(--action-color), 0.5);
	height: 1px;
	position: relative;

	transition: background-color var(--transition-duration);
}

#navbar-component .navbar-toggler-icon::before,
#navbar-component .navbar-toggler-icon::after {
	content: '';
	background-color: inherit;
	height: inherit;
	width: inherit;
	position: absolute;
	right: 0;
	left: unset;
}

#navbar-component .navbar-toggler-icon::before {
	top: -6px;
	width: 45%;

	transition: width var(--transition-duration);
}

#navbar-component .navbar-toggler-icon::after {
	top: 6px;
}

#navbar-component .navbar-toggler:hover .navbar-toggler-icon,
#navbar-component .navbar-toggler:focus .navbar-toggler-icon {
	background-color: rgba(var(--action-color), 1);
}

#navbar-component .navbar-toggler:hover .navbar-toggler-icon::before,
#navbar-component .navbar-toggler:focus .navbar-toggler-icon::before {
	width: 100%;
}

/* Ramadan Offers */
#navbar-component .offers {
	animation: shine 1000ms infinite alternate both;
}

#navbar-component .offers.active,
#navbar-component .offers:hover {
	animation-name: none;
}

@keyframes shine {
	0%,
	100% {
		color: rgba(var(--action-color), 0.5);
	}

	50% {
		color: rgba(var(--action-color), 1);
	}
}

/* RTL */
[dir='rtl'] #navbar-component .navbar-toggler-icon::before,
[dir='rtl'] #navbar-component .navbar-toggler-icon::after {
	left: 0;
	right: unset;
}

[dir='rtl'] #navbar-component .nav-link,
[dir='rtl'] #navbar-component #translation {
	font-size: 1.2rem;
}

@media only screen and (min-width: 992px) and (max-width: 1200px) {
	[dir='rtl'] #navbar-component .nav-link,
	[dir='rtl'] #navbar-component #translation {
		font-size: 1rem;
	}
}
