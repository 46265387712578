/* Offer Card */
#ramadan-offers-page .about-section {
    padding-top: 0 !important;
    padding-bottom: 6rem !important;
}

/* Text Container */
#ramadan-offers-page .about-section:nth-child(even) .text-container {
    order: -1;
}

/* Images Container */
#ramadan-offers-page .about-section:nth-child(even) .images-container {
    direction: rtl;
}

/* RTL */
[dir='rtl'] #ramadan-offers-page .about-section:nth-child(even) .images-container {
    direction: ltr;
}
