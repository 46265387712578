#catering-page .about-section {
    padding-top: 0 !important;
    padding-bottom: 6rem !important;
}

#catering-page form {
    background-color: rgba(var(--card-color), 1);
    border: 1px solid rgba(var(--action-color), 1);
    border-radius: 0.375rem;
}

#catering-page form .page-title {
    width: fit-content;
    border-bottom: 2px solid rgba(var(--action-color), 1);
}
