.btn {
    border-radius: 0 !important;
    border: 2px solid rgba(var(--btn-color), 1) !important;
    background-color: rgba(var(--btn-color), 1) !important;
    padding: 0.8rem 1.6rem !important;
    letter-spacing: 2px;

    transition: background-color var(--transition-duration);
}

.btn:hover {
    background-color: transparent !important;
    color: rgba(var(--title-color), 1);
}

/* RTL */
[dir='rtl'] .btn {
    letter-spacing: normal !important;
}
