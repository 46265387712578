.menu-category {
    background-color: transparent;
    border: none;
    border-radius: 0;
    color: rgba(var(--title-color), 1);
}

/* Category Card Head */
.menu-category .category-head {
    margin: 0;
    padding-top: var(--height);
    padding-bottom: var(--height);
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)) border-box, var(--image-url);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.menu-category .category-head::after {
    content: '';
    position: absolute;
    bottom: -0.5rem;
    left: 0;
    width: 100%;
    height: 1px;
    border-bottom: 2px dashed #1d2628;
}

/* Category Title */
.menu-category .category-head .title {
    max-width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

/* Category Items Count */
.menu-category .items-count {
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    background-color: #131a1c;
    color: rgba(var(--action-color), 1);
    font-size: 1.5rem;
}
