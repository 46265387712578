/* Slider Container */
#home-page .slider-component {
    height: 75vh;
}

.slider-component .slider-container {
    position: relative;
}

/* Slide Overlay */
.slider-component .slider-container::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1));
}

/* Slider Text Container */
.slider-component .slider-text {
    color: rgba(var(--title-color), 1);

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 33;
}

/* Slider Title */
.slider-container .slider-text .title {
    font-size: 4rem;
    line-height: 1;
    font-weight: 600;
    margin: 0.5rem 0 1.5rem;
}

.slider-container .slider-text .title,
.slider-container .slider-text .subtitle,
.slider-container .slider-text .button {
    animation-name: none;
    animation-play-state: paused;
}

.slider-container.swiper-slide-active .slider-text .title,
.slider-container.swiper-slide-active .slider-text .subtitle {
    animation-name: fadeInUp;
    animation-play-state: running;
}

.slider-container.swiper-slide-active .slider-text .button {
    animation-name: zoomIn;
    animation-play-state: running;
}

/* RTL */
[dir='rtl'] .slider-container .slider-text .title {
    line-height: 1.5;
}
