/* Card Container */
.feature-card-component {
    color: rgba(var(--title-color), 1);
    background-color: rgba(var(--card-color), 1);
    padding: 2rem 2.5rem;
}

/* Card Image */
.feature-card-component img {
    padding-right: 1rem;
    height: 4rem;
    filter: invert(88%) sepia(12%) saturate(5382%) hue-rotate(316deg) brightness(98%) contrast(86%);
}

.feature-card-component:hover img {
    animation: spin calc(1.5*var(--transition-duration)) linear 0s 2 normal both;
}

@keyframes spin {

    0%,
    100% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.1);
    }
}

/* Card Title */
.feature-card-component .title {
    font-size: 18px;
    line-height: 1.6;
}

/* Card Description */
.feature-card-component .description {
    color: rgba(255, 255, 255, 0.5);
    font-size: 14px;
}

/* RTL */

[dir='rtl'] .feature-card-component img {
    padding-left: 1rem;
    padding-left: unset;
}
