/* Whatsapp Icon */
#whatsapp-icon-component {
    cursor: pointer;
    position: fixed;
    bottom: 3rem;
    right: 3rem;
    z-index: 88;
    width: 4rem;
    border-radius: 50%;
    padding: 0.5rem !important;
    background-color: #54cc61;
}

/* Tooltip Text */
.tooltip .tooltip-inner {
    max-width: fit-content !important;
    background-color: rgba(255, 255, 255, 1);
    color: rgba(var(--card-color), 1);
}

/* Tooltip Arrow */
.tooltip-arrow::before {
    border-right-color: rgba(255, 255, 255, 1) !important;
}

/* RTL */
[dir='rtl'] #whatsapp-icon-component {
    right: unset;
    left: 3rem;
}

[dir='rtl'] .tooltip-arrow::before {
    border-right-color: transparent !important;
    border-left-color: rgba(255, 255, 255, 1) !important;
}
