.PhoneInputInput,
.PhoneInputCountry {
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    border: 1px solid #ced4da;
}

.PhoneInput {
    border: 1px solid #ced4da !important;
    border-radius: 0.375rem;
    display: flex !important;
    align-items: center !important;
    background-color: #ffffff !important;
}

.PhoneInput.is-invalid {
    border-color: #dc3545 !important;
}

.PhoneInput.is-invalid:has(>.PhoneInputInput:focus) {
    box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
    -moz-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
    -webkit-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
    -ms-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
    -o-box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25) !important;
}

.PhoneInput.is-invalid .PhoneInputInput {
    padding-right: calc(1.5em + 0.75rem);
    background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e');
    background-repeat: no-repeat;
    background-position: right calc(0.375em + 0.1875rem) center;
    background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}

.PhoneInputInput {
    border: none !important;
    border-radius: 0 0.375rem 0.375rem 0;
}

.PhoneInputCountry {
    border: none !important;
    border-right: 1px solid #ced4da !important;
    border-left: none !important;
    border-radius: 0.375rem 0 0 0.375rem;
    margin: 0 !important;
}

.PhoneInput--focus .PhoneInputInput,
.PhoneInput--focus .PhoneInputCountry {
    color: #212529 !important;
    background-color: #fff !important;
    outline: 0 !important;
}

.PhoneInput--focus .PhoneInputInput {
    border-color: #86b7fe #86b7fe #86b7fe #ced4da !important;
}

.PhoneInput--focus .PhoneInputCountry {
    border-color: #86b7fe #ced4da #86b7fe #86b7fe !important;
}

.PhoneInputCountryIcon--border {
    background-color: transparent;
    box-shadow: none;
}

.PhoneInputCountrySelect:focus+.PhoneInputCountryIcon--border {
    box-shadow: none;
}

.PhoneInputCountry svg {
    vertical-align: top;
}

/* RTL */
[dir='rtl'] .PhoneInput.is-invalid .PhoneInputInput,
[dir='rtl'] .was-validated .PhoneInput:invalid .PhoneInputInput {
    padding-right: 0.75rem;
    padding-left: calc(1.5em + 0.75rem);
    background-position: left calc(.375em + .1875rem) center;
}

[dir='rtl'] .PhoneInput.is-invalid .PhoneInputInput:focus {
    clip-path: inset(-0.25rem 0 -0.25rem -0.25rem);
}

[dir='rtl'] .PhoneInput.is-invalid .PhoneInputCountry:has(+.PhoneInputInput:focus) {
    clip-path: inset(-0.25rem -0.25rem -0.25rem 0);
}

[dir='rtl'] .PhoneInputInput {
    border-radius: 0.375rem 0 0 0.375rem;
}

[dir='rtl'] .PhoneInputCountry {
    border-radius: 0 0.375rem 0.375rem 0;
    border-left: 1px solid #ced4da !important;
    border-right: none !important;
}

[dir='rtl'] .PhoneInputCountrySelectArrow {
    margin-left: 0;
    margin-right: var(--PhoneInputCountrySelectArrow-marginLeft);
}

[dir='rtl'] .PhoneInput--focus .PhoneInputInput {
    border-color: #86b7fe #ced4da #86b7fe #86b7fe !important;
}

[dir='rtl'] .PhoneInput--focus .PhoneInputCountry {
    border-color: #86b7fe #86b7fe #86b7fe #ced4da !important;
}
