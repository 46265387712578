.menu-item {
    background-color: transparent;
    border: none;
    border-radius: 0;
    border-bottom: 2px solid #1d2628;
    color: rgba(var(--title-color), 1);
}

.menu-item .card-body {
    padding: 1rem 0.5rem;
}

/* Item Image */
.menu-item .image-container {
    height: 6rem;
    background: var(--image-url);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.menu-item .image-container.icon-container {
    height: 3rem;
    background-size: contain;
    filter: invert(88%) sepia(12%) saturate(5382%) hue-rotate(316deg) brightness(98%) contrast(86%);

}

/* Item Title */
.menu-item .title {
    font-size: 1rem;
    max-width: 65%;
}

.menu-item .title.full-width {
    max-width: 100%;
}

/* Item Price */
.menu-item .price {
    color: rgba(var(--action-color), 1);
    font-size: 1rem;
}
