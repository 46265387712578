#loading-component {
    background-color: rgba(var(--primary-color), 1);
    width: 100%;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}

#loading-component>.row {
    height: 100%;
}

#loading-component img {
    max-height: 18rem;
}
