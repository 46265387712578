/* ********************************************************** */
/* ******************** Features Section ******************** */
/* ********************************************************** */
#home-page #features-section {
    position: relative;
    transform: translateY(-50%);
    z-index: 44;
}

@media only screen and (max-width: 768px) {
    #home-page #features-section {
        transform: translateY(0);
        padding: 0;
    }
}

/* ********************************************************** */
/* ********************** About Section ********************* */
/* ********************************************************** */
#home-page .about-section {
    padding-top: 3rem !important;
    padding-bottom: 6rem !important;
}

#home-page .description * {
    text-align: justify;
}

/* ********************************************************** */
/* ******************** Catering Section ******************** */
/* ********************************************************** */
#home-page #catering-section {
    background-color: #06090a;
    padding-top: 7.5rem !important;
    padding-bottom: 7.5rem !important;
}

/* Text Container */
#home-page #catering-section .text-container {
    color: rgba(var(--title-color), 1);
    text-align: center;
    padding: 3rem;
}

@media only screen and (max-width: 768px) {
    #home-page #catering-section .text-container {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
}

/* Section Title */
#home-page #catering-section .text-container .title {
    font-size: 4rem;
    line-height: 1;
    margin: 1rem 0;
}

@media only screen and (max-width: 992px) {
    #home-page #catering-section .text-container .title {
        font-size: 2.5rem;
    }
}

/* Section Description */
#home-page #catering-section .text-container .description {
    color: rgba(255, 255, 255, 0.5);
    margin: 1rem 0 2rem;
    text-align: justify;
}

/* Section Images */
#home-page #catering-section .image-container .image::after {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    width: 50%;
    opacity: 0;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-25deg);

    transition-property: left, opacity;
    transition-duration: calc(2*var(--transition-duration));
}

#home-page #catering-section .image-container:hover .image::after {
    opacity: 1;
    left: 125%;
}

/* RTL */
[dir='rtl'] #home-page #catering-section .image-container .image::after {
    right: -75%;
    left: unset;
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(25deg);
    transition-property: right, opacity;
}

[dir='rtl'] #home-page #catering-section .image-container:hover .image::after {
    right: 125%;
}

[dir='rtl'] #home-page #catering-section .text-container .title {
    line-height: 1.5;
}

/* ********************************************************** */
/* ********************** Menu Section ********************** */
/* ********************************************************** */
#home-page #menu-section {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

/* Text Container */
#home-page #menu-section .text-container {
    color: rgba(var(--title-color), 1);
    text-align: start;
    padding-bottom: 3rem;
}

@media only screen and (max-width: 768px) {
    #home-page #menu-section .text-container {
        padding-right: 0.5rem;
        padding-left: 0.5rem;
    }
}

/* Section Title */
#home-page #menu-section .text-container .title {
    font-size: 4rem;
    line-height: 1;
    margin: 1rem 0;
}

@media only screen and (max-width: 992px) {
    #home-page #menu-section .text-container .title {
        font-size: 2.5rem;
    }
}

/* RTL */
[dir='rtl'] #home-page #menu-section .text-container .title {
    line-height: 1.5;
}

/* ********************************************************** */
/* ********************** Video Section ********************* */
/* ********************************************************** */
#home-page #video-section {
    min-height: 75vh;
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), var(--image-url);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: local, fixed;
}

@keyframes custom_heartBeat {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    14% {
        -webkit-transform: scale(1.15);
        transform: scale(1.15);
    }

    28% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }

    42% {
        -webkit-transform: scale(1.15);
        transform: scale(1.15);
    }

    70% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}

/* ********************************************************** */
/* ********************* Contact Section ******************** */
/* ********************************************************** */
#home-page #contact-section {
    padding-top: 6rem !important;
    padding-bottom: 6rem !important;
}

/* Text Container */
@media only screen and (min-width: 992px) {
    #home-page #contact-section .text-container {
        padding-top: 5rem;
        padding-bottom: 5rem;
    }
}

#home-page #contact-section .text-container .text {
    background-color: #06090a;
    color: rgba(var(--title-color), 1);
    padding: 3.5rem !important;
}

/* Opening Times */
#home-page #contact-section .opening-time-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    padding: 0.5rem 0;
}

/* Card Image */
#home-page #contact-section .text-container img {
    height: 4rem;
    filter: invert(88%) sepia(12%) saturate(5382%) hue-rotate(316deg) brightness(98%) contrast(86%);
}

/* Section Image */
@media only screen and (max-width: 992px) {
    #home-page #contact-section .image-container {
        height: 12rem;
    }
}

#home-page #contact-section .image-container .image {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), var(--image-url);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

#home-page #contact-section .image-container .image::after {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    width: 50%;
    opacity: 0;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-25deg);

    transition-property: left, opacity;
    transition-duration: calc(2*var(--transition-duration));
}

#home-page #contact-section .image-container:hover .image::after {
    opacity: 1;
    left: 125%;
}

/* Default Number Container */
#home-page #contact-section .default-number-container {
    background-color: #0d1213;
    color: rgba(var(--title-color), 1);
    padding: 3.5rem;

    display: flex;
    align-items: center;
}

#home-page #contact-section .number a {
    text-decoration: none;
    color: rgba(var(--action-color), 0.5);

    transition: color var(--transition-duration);
}

#home-page #contact-section .number:hover a {
    color: rgba(var(--action-color), 1);
}

/* RTL */
[dir='rtl'] #home-page #contact-section .image-container .image::after {
    right: -75%;
    left: unset;
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(25deg);
    transition-property: right, opacity;
}

[dir='rtl'] #home-page #contact-section .image-container:hover .image::after {
    right: 125%;
}
