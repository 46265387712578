#breadcrumb-component {
	position: relative;
	background-color: transparent;
	color: rgb(255, 255, 255, 1);
	background: linear-gradient(to bottom, rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.1)),
		var(--image-url);
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	background-attachment: fixed;
}

@media only screen and (max-width: 1400px) {
	#breadcrumb-component {
		background-position: center;
	}
}

.breadcrumb-item {
	color: #eeeeee;
	text-decoration: none;
	font-size: 16px;
	transition: color 250ms;
}

.breadcrumb-item + .breadcrumb-item::before {
	color: #eeeeee;
}

.breadcrumb-item:hover,
.breadcrumb-item.active {
	color: rgba(var(--action-color), 1) !important;
}

.breadcrumb-item.active {
	cursor: default;
}

/* RTL */
[dir='rtl'] .breadcrumb-item + .breadcrumb-item::before {
	float: right;
	padding-left: var(--bs-breadcrumb-item-padding-x);
}
