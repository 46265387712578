/* Offer Card */
#special-offers-page .about-section {
	padding-top: 0 !important;
	padding-bottom: 6rem !important;
}

/* Text Container */
#special-offers-page .about-section:nth-child(even) .text-container {
	order: -1;
}

/* Images Container */
#special-offers-page .about-section:nth-child(even) .images-container {
	direction: rtl;
}

/* RTL */
[dir='rtl']
	#special-offers-page
	.about-section:nth-child(even)
	.images-container {
	direction: ltr;
}
