.icon-text {
    width: fit-content;
    margin-bottom: 8px;
}

.icon-text:last-child {
    margin-right: 0 !important;
    margin-left: 0 !important;
}

.icon-text .value,
.icon-text .text-value {
    margin-bottom: 0.5rem;
    padding: 0.25rem !important;
    padding-top: 0 !important;
}

.icon-text .value:last-child {
    margin-bottom: 0;
}

.icon-text .icon {
    width: fit-content;
}

.icon-text svg {
    width: calc(var(--size)*1px);
    height: calc(var(--size)*1px);
    color: rgba(255, 255, 255, 0.5);

    transition: color var(--transition-duration);
}

.icon-text a,
.icon-text .text-value {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.5);

    transition: color var(--transition-duration);
}

.icon-text a:hover,
.icon-text .text-value:hover,
.icon-text:hover svg {
    color: rgba(var(--action-color), 1);
}

/* RTL */
[dir='rtl'] .icon-text svg {
    transform: scaleX(-1);
}
