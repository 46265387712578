/* Image Thumbnail */
#gallery-page img {
    height: 12rem;
    object-fit: cover;
    object-position: center;

    transition: transform calc(1.5*var(--transition-duration));
}

#gallery-page img:hover {
    cursor: pointer;
    transform: scale(1.1);
}
