/* Map Iframe */
#contact-us-page iframe {
    width: 100% !important;
    height: 24rem !important;
}

/* Contact Form */
#contact-us-page form {
    background-color: rgba(var(--card-color), 1);
    border: 1px solid rgba(var(--action-color), 1);
    border-radius: 0.375rem;
}

/* Card Image */
#contact-us-page .text-container img {
    height: 4rem;
    filter: invert(88%) sepia(12%) saturate(5382%) hue-rotate(316deg) brightness(98%) contrast(86%);
}

/* Card Title */
#contact-us-page .text-container .title {
    color: rgba(var(--title-color), 1);
}

/* Social Media Accounts */
#contact-us-page .list-group-item {
    border: none !important;
    border-radius: 0.5rem !important;
    background-color: transparent !important;
}

#contact-us-page .list-group-item:not(:last-of-type) {
    margin-right: 0.5rem;
}

#contact-us-page .social-accounts * {
    color: #ffffff;

    transition-property: border-color, color, transform;
    transition-duration: 250ms;
}

#contact-us-page .social-accounts>* * {
    transform: translateY(0);
    animation: none;
}

#contact-us-page .social-accounts>*:hover * {
    color: var(--icon-color) !important;
    animation: hover 1000ms infinite alternate ease-in-out;
}

@keyframes hover {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-0.3rem);
    }
}

/* RTL */
[dir='rtl'] #contact-us-page .list-group-item:not(:last-of-type) {
    margin-right: 0;
    margin-left: 0.5rem;
}
