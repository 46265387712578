.about-card-component {
    background-color: rgba(var(--card-color), 1);
    padding: 1.5rem 2rem;
    height: 25rem;
}

@media only screen and (max-width: 768px) {
    .about-card-component {
        height: fit-content;
    }
}

.about-card-component * {
    margin: 0;
    padding: 0;
}

/* Card Image */
.about-card-component .image {
    height: 6rem;
    object-fit: contain;
    object-position: center;
}

/* Card Title */
.about-card-component .title {
    color: rgba(var(--title-color), 1);
    margin: 1.5rem 0 0.8rem;
    font-weight: 600;
}

/* Card Description */
.about-card-component .description {
    color: rgba(255, 255, 255, 0.5);
    text-align: justify;
}
