.video-modal .modal-content {
    border-radius: 0 !important;
    height: 50vh;
}

.video-modal .modal-body {
    padding: 0 !important;
    border-radius: 0 !important;

    background-color: rgba(var(--card-color), 1);
}

.video-modal iframe {
    width: 100% !important;
    height: 100% !important;
}
