#footer-component {
    padding: 1.2rem 1.2rem;
    background-color: #f9fafd;
    border-top: 1px solid #e5f1fb;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), var(--image-url);
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

#footer-component .title {
    color: rgba(var(--title-color), 1);
}

/* Company Logo */
#footer-component .logo {
    max-height: 8rem;
}

/* Social Media Accounts */
#footer-component .list-group-item {
    border: none !important;
    border-radius: 0.5rem !important;
    background-color: transparent !important;
}

#footer-component .list-group-item:not(:last-of-type) {
    margin-right: 0.5rem;
}

#footer-component .social-accounts * {
    color: rgba(255, 255, 255, 1);

    transition-property: border-color, color, transform;
    transition-duration: 250ms;
}

#footer-component .social-accounts>* * {
    transform: translateY(0);
    animation: none;
}

#footer-component .social-accounts>*:hover * {
    color: var(--icon-color) !important;
    animation: hover 1000ms infinite alternate ease-in-out;
}

@keyframes hover {
    from {
        transform: translateY(0);
    }

    to {
        transform: translateY(-0.3rem);
    }
}

/* RTL */
[dir='rtl'] #footer-component .list-group-item:not(:last-of-type) {
    margin-right: 0;
    margin-left: 0.5rem;
}

/* Important Links */
#footer-component li {
    padding: 0.1rem;
    margin-top: 0.5rem;
}

/* Gallery */
#footer-component .footer-slider-component {
    height: 100%;
}

#footer-component .gallery img {
    height: 12rem;
    object-fit: cover;
    object-position: center;
    border: none !important;

    transition: background-color calc(1.5*var(--transition-duration));
}

#footer-component .gallery img:hover {
    cursor: pointer;
    background-color: rgba(var(--action-color), 1);
}

/* Contact Info */
#footer-component a {
    color: rgba(255, 255, 255, 1);
    text-decoration: none;

    transition: color 250ms;
}

#footer-component a:hover {
    color: rgba(var(--action-color), 1);
}

#footer-component .icon-text svg {
    color: rgba(255, 255, 255, 1);
}

#footer-component .icon-text:hover svg {
    color: rgba(var(--action-color), 1);
}

#footer-component .phone-number {
    direction: ltr;
}

/* Company Signature */
.company-signature a {
    color: #ffffff;
    text-decoration: none;
    font-weight: 600;

    transition: color 350ms;
}

.company-signature a:hover,
.company-signature a:focus {
    color: rgba(var(--action-color), 1);
}
