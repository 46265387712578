.about-section {
    padding-top: 3rem !important;
    padding-bottom: 6rem !important;
}

/* Section Images */
.about-section .image-container .image img {
    cursor: pointer;
    object-fit: cover;
    object-position: center;
    height: 24rem !important;
}

.about-section .image-container .image::after {
    content: "";
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    cursor: pointer;
    display: block;
    width: 50%;
    opacity: 0;
    height: 100%;
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(-25deg);

    transition-property: left, opacity;
    transition-duration: calc(2*var(--transition-duration));
}

.about-section .image-container:hover .image::after {
    opacity: 1;
    left: 125%;
}

.about-section .image-container:nth-child(even) .image {
    margin-top: 2rem;
}

/* Text Container */
.about-section .text-container {
    color: rgba(var(--title-color), 1);
    padding-left: 3rem;
    padding-right: 0.5rem;
}

.about-section:nth-child(even) .text-container {
    padding-right: 3rem;
    padding-left: 0.5rem;
}

@media only screen and (max-width: 992px) {
    .about-section .text-container {
        padding-left: 0.5rem;
    }

    .about-section:nth-child(even) .text-container {
        padding-right: 0.5rem;
    }
}

/* Section Title */
.about-section .text-container .title {
    font-size: 3rem;
    line-height: 1;
    margin: 1rem 0;
}

@media only screen and (max-width: 992px) {
    .about-section .text-container .title {
        font-size: 2.5rem;
    }
}

/* Section Description */
.about-section .text-container .description {
    color: rgba(255, 255, 255, 0.5);
    margin: 1rem 0 2rem;
    text-align: justify;
}

/* RTL */
[dir='rtl'] .about-section .image-container .image::after {
    right: -75%;
    left: unset;
    background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0.3) 100%);
    transform: skewX(25deg);
    transition-property: right, opacity;
}

[dir='rtl'] .about-section .image-container:hover .image::after {
    right: 125%;
}

[dir='rtl'] .about-section .text-container {
    padding-right: 3rem;
    padding-left: 0.5rem;
}

[dir='rtl'] .about-section:nth-child(even) .text-container {
    padding-right: 0.5rem;
    padding-left: 3rem;
}

[dir='rtl'] .about-section .text-container .title {
    line-height: 1.5;
}

@media only screen and (max-width: 992px) {
    [dir='rtl'] .about-section .text-container {
        padding-right: 0.5rem;
    }

    [dir='rtl'] .about-section:nth-child(even) .text-container {
        padding-left: 0.5rem;
    }
}
